@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
}

body > #root,
body > #root > div {
  height: 100%;
}

html {
  font-family: "Twemoji Country Flags", "Manrope", Arial, Helvetica, sans-serif;
}

.font-manrope {
  font-family: "Manrope", Arial, Helvetica, sans-serif;
}

.font-montserrat {
  font-family: "Montserrat", Courier;
}

.shadow-up {
  box-shadow: 0 -3px 6px rgba(0,0,0,0.09), 0 -2px 6px rgba(0,0,0,0.18);}
